<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      Borç<br />
      <small>{{ originalData.debt_number }}</small>
    </template>
    <template slot="body" v-if="originalData.id">
      <v-row dense cols="12">
        <v-col sm="3"><strong>TYA</strong></v-col>
        <v-col sm="3">
          <router-link
            :to="{
              name: 'definitions.clusters.show',
              params: {
                id: originalData.house_assessment.house.block.cluster_id,
              },
            }"
          >
            {{ originalData.house_assessment.house.block.cluster.name }}
          </router-link>
        </v-col>
        <v-col sm="3"><strong>Daire</strong></v-col>
        <v-col sm="3">{{
          originalData.house_assessment.house.door_number
        }}</v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Borçlandırma Türü</strong></v-col>
        <v-col sm="3">
          {{ originalData.house_assessment.income_type.name }}
        </v-col>
        <v-col sm="3"><strong>Sistem No</strong></v-col>
        <v-col sm="3">{{ originalData.debt_number }}</v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Tahakkuk Tarihi</strong></v-col>
        <v-col sm="3">
          {{
            new Date(
              originalData.house_assessment.issues_on
            ).toLocaleDateString()
          }}
        </v-col>
        <v-col sm="3"><strong>Son Ödeme Tarihi</strong></v-col>
        <v-col sm="3">
          {{
            new Date(
              originalData.house_assessment.last_payment_on
            ).toLocaleDateString()
          }}
        </v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col sm="3"><strong>Borçlu</strong></v-col>
        <v-col sm="3">
          <router-link
            :to="{
              name: 'definitions.residents.show',
              params: { id: originalData.resident_id },
            }"
          >
            {{ originalData.resident.name }}
          </router-link>
        </v-col>
        <!-- <v-col sm="3"><strong>Tutar</strong></v-col>
        <v-col sm="3">
          <rs-table-cell-number price :value="originalData.amount" />
        </v-col> -->
      </v-row>

      <v-row dense>
        <v-col>
          <strong>Açıklama</strong>
          <p>
            {{ originalData.description }}
          </p>
        </v-col>
      </v-row>

      <v-row dense cols="12">
        <v-col cols="12">
          <h3>Tahsilatlar</h3>
        </v-col>

        <v-col cols="12">
          <v-btn
            v-if="originalData.debt_collectings.length"
            class="float-end"
            color="pink"
            outlined
            small
            @click="handleDeleteClick"
            :disabled="selectedItems.length === 0"
            :loading="isLoading"
          >
            Borç Kapamayı İptal Et
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="originalData.debt_collectings.length">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th>Tahsilat Tarihi</th>
                  <th>Ödeyen Kişi</th>
                  <th>Tahsilat Türü</th>
                  <th>Ödenen Tutar</th>
                  <th>Ödenen Ana Para</th>
                  <th>Ödenen Gecikme</th>
                  <th>Fark Gün</th>
                  <th>Açıklama</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in originalData.debt_collectings"
                  :key="item.id"
                >
                  <td>
                    <v-checkbox
                      v-model="selectedItems"
                      label=""
                      :value="item"
                    />
                  </td>
                  <td>
                    <rs-table-cell-date :value="item.collecting.collected_on" />
                  </td>
                  <td>
                    {{ item.collecting.resident.name }}
                  </td>
                  <td>
                    {{ item.collecting.collecting_type.name }}
                  </td>
                  <td>
                    <rs-table-cell-number
                      price
                      :value="item.amount + item.deferment_amount"
                    />
                  </td>
                  <td>
                    <rs-table-cell-number price :value="item.amount" />
                  </td>
                  <td>
                    <rs-table-cell-number
                      price
                      :value="item.deferment_amount"
                    />
                  </td>
                  <td>
                    {{ getDayDiff(item) }}
                  </td>
                  <td>{{ item.debt.house_assessment.description }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12" v-else>
          <p>Bu borca hiç tahsilat yapılmadı.</p>
        </v-col>
      </v-row>

      <rs-form-buttons
        :is-loading="isLoading"
        @cancel="hide()"
        hide-submit
        hide-save-close
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteSubmitClick"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import validation from "@/core/validation";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters([]),
  },
  data() {
    return {
      id: null,
      isLoading: false,
      rules: validation,
      originalData: {},
      selectedItems: [],
    };
  },
  methods: {
    show(id) {
      this.originalData = {};

      this.id = id;
      this.load();
      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      if (this.isLoading || !this.id) return false;

      this.isLoading = true;
      this.selectedItems = [];

      this.$api
        .query(`income/debts/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDeleteClick() {
      if (this.selectedItems.length === 0) {
        return;
      }

      this.$refs.confirmDelete.show(
        "Borç kapamayı iptal etmek istediğinizden emin misiniz?"
      );
    },
    handleDeleteSubmitClick() {
      this.$refs.confirmDelete.hide();
      this.isLoading = true;

      // TODO: replace this dummy method with real api call

      this.$api
        .post(`income/cancel-debt-collectings`, {
          debt_collecting_ids: this.selectedItems.map((item) => item.id),
        })
        .then(() => {
          this.$toast.success("Borç kapama başarıyla iptal edildi.");
          this.load();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.load();
        });
    },
    getDayDiff(item) {
      const dt1 = new Date(this.originalData.house_assessment.last_payment_on);
      const dt2 = new Date(item.collecting.collected_on);

      const diff = Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );

      return diff > 0 ? diff : 0;
    },
  },
};
</script>
